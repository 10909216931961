import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@mui/material';
import {
  StyledEngineProvider,
  experimental_extendTheme as extendTheme,
  Experimental_CssVarsProvider as CssVarsProvider
} from '@mui/material/styles';

import lightPalette from './paletteLight';
import darkPalette from './paletteDark';
import { createShadow, createCustomShadow } from './shadows';
import components from './components';
import typography from './typography';

const ThemeConfig = ({ children }) => {
  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: lightPalette,
        shadows: createShadow(false),
        customShadows: createCustomShadow(false),
        components: components(lightPalette, createCustomShadow(false))
      },
      dark: {
        palette: darkPalette,
        shadows: createShadow(true),
        customShadows: createCustomShadow(true),
        components: components(darkPalette, createCustomShadow(true))
      }
    },
    shape: {
      borderRadius: 8,
      borderRadiusSm: 12
    },
    breakpoints: {
      values: {
        xss: 0,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600
      }
    },
    typography
  });

  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme} defaultMode="system">
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

ThemeConfig.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeConfig;
